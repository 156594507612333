import React, {Component} from "react";
import axios from "axios";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import toNlDate from "../../../services/toNlDate";

export class WaitingForDelivery extends Component {

  render() {
    return <div className="grid">
      <div className="p-col-12">
        <DataTable paginator={true}
                   rows={20}
                   value={this.state.rows}
                   style={{marginTop: '5px'}}>
          <Column field="id"
                  style={{width:'100px'}}
                  header="ID"
                  className="t-right"/>
          <Column field="display_name"
                  header="Naam"
                  />
          <Column field="odoo_date_planned"
                  body={(row) => toNlDate(row.odoo_date_planned)}
                  header="Naam"/>

        </DataTable>
      </div>
    </div>
  }

  state = {
    rows: []
  }

  componentDidMount() {

    axios.get(process.env.REACT_APP_MTG_BACK + '/waiting-for-delivery').then(response => {
      this.setState({
        rows: response.data,
      })
    })

  }

}