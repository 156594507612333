import React from "react"
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Messages} from 'primereact/messages';

import {InputText} from 'primereact/inputtext';
import axios from 'axios';
import AddVouchersDialog from "../dialogs/AddVouchersDialog";
import UploadVouchersDialog from "../dialogs/UploadVouchersDialog";
import DownloadVouchers from '../download/DownloadVouchers'
import DialogAreYouSure from '../dialogs/DialogAreYouSure'
import DialogVoucherOrders from '../dialogs/DialogVoucherOrders'
import EditVoucherDialog from "../dialogs/vouchers/EditVoucherDialog";

const BACKEND = process.env.REACT_APP_MTG_BACK
const WEBSHOP = process.env.REACT_APP_MTG_WEBSHOP

let style = {
  icon: {fontSize: '1.5em', cursor: 'pointer'}
}

class Vouchers extends React.Component {

  render() {

    return (
      <div>
        <Messages ref={(el) => this.messages = el}></Messages>
        {/* ========================== Dialogs ============================ */}
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
        <DialogVoucherOrders ref={this.DialogVoucherOrders}/>
        <EditVoucherDialog
          ref={this.EditVoucherDialog}
          voucherAmount={this.state.voucher_amount}
        />

        <UploadVouchersDialog
          ref={this.UploadVouchersDialog}
          onDialogUploadedVouchers={this.onDialogUploadedVouchers}
          {...this.props}/>
        <AddVouchersDialog
          ref={this.AddVouchersDialog}
          onDialogAddedVouchers={this.onDialogAddedVouchers}
          {...this.props}/>

        <div className="p-grid">
          {/* ========================== Buttons ============================ */}
          <div className="p-col-2">
            <Button label="Add" icon="pi pi-plus"
                    onClick={() => this.AddVouchersDialog.current.open()}
                    style={{margin: '0 2px'}}
            />
          </div>
          <div className="p-col-2" style={{textAlign: 'center'}}>
            <Button label="Upload" icon="pi pi-cloud-upload" className="p-button-warning"
                    onClick={() => this.UploadVouchersDialog.current.open(() => {
                      this.getVouchers()
                    })}
                    style={{margin: '0 2px'}}
            />
          </div>
          <div className="p-col-5">
            <Button label="Mass activate" icon="pi pi-exclamation-circle"
                    onClick={() => this.activateAllVouchers()}
                    style={{margin: '0 2px'}}
            />

            <Button label="Verstuur inloglink" icon="pi pi-exclamation-circle"
                    onClick={() => this.sendLoginLink()}
                    style={{margin: '0 2px'}}
            />

            <Button label="Verwijder vouchers" icon="pi pi-exclamation-circle"
                    onClick={() => this.deleteVouchers()}
                    style={{margin: '0 2px'}}
            />
          </div>
          <div className="p-col-3">
            <DownloadVouchers webshop={this.props.webshop}/>
          </div>
          {/* ========================== Data ============================ */}
          <div className="p-col-12">
            <DataTable value={this.state.rows}
                       paginator={true}
                       rows={15}
                       editable={true}
                       removableSort={true}
                       scrollHeight="600px"
                       style={{marginTop: '5px'}}>
              <Column header="Edit" body={(row) => {
                return (
                  <i className="pi pi-user-edit"
                     style={{...style.icon}}
                     onClick={() => this.EditVoucherDialog.current.open(row.id, (newRow) => {
                       let index = this.state.rows.findIndex((el) => row.id === el.id)
                       let clone = [...this.state.rows]

                       clone[index] = newRow
                       this.setState({rows: clone})
                     })}>
                  </i>
                )
              }} style={{width: '50px', textAlign: 'center'}}/>
              
              <Column field="voucher_follow_no" header="Volgnummer" filter={true} style={{width: '7.5em'}} filterMatchMode={'contains'}
                      body={(row) => {
                        return row.voucher_follow_no
                      }}/>
              <Column field="voucher_no" header="No" filter={true} style={{width: '7.5em'}} filterMatchMode={'contains'}
                      body={(row) => {
                        return row.voucher_no
                      }}/>
              <Column field="username" header="User" filter={true} filterMatchMode={'contains'}/>
              <Column field="enabled" header="Pass" style={{width: '5em', textAlign: 'center'}}/>
              <Column field="naam" header="Naam" filter={true} filterMatchMode={'contains'}/>
              <Column field="email" header="Email" filter={true} filterMatchMode={'contains'}/>
              <Column field="voucher_balance" header="Balance"
                      style={{width: '6em', textAlign: 'right'}}/>
              <Column header="Order(s)"
                      style={{width: '5rem'}}
                      body={(row) => {
                        return row.has_orders === 1 ?
                          <i className="pi pi-shopping-cart" style={{...style.icon, marginLeft: '1.2rem'}}
                             onClick={this.showOrders.bind(this, row)}>
                          </i> : ''
                      }}/>
              <Column header="Zet uit" body={(row) => {
                return (
                  row.has_orders === 0 ? <i className="pi pi-times"
                                            style={{
                                              ...style.icon,
                                              color: (row.enabled === '######' ? 'red' : '#bbbbbb')
                                            }}
                                            onClick={this.disableOrEnableVoucher.bind(this, row)}>
                  </i> : ''
                )
              }} style={{width: '75px', textAlign: 'center'}}/>

              <Column header="Activeer" body={(row) => {
                return (
                  (row.enabled === '******') ?
                    <i className="pi pi-fw pi-undo" style={{...style.icon, color: '#bbbbbb'}}
                       onClick={this.newPassword.bind(this, row)}>
                    </i> :
                    (row.enabled !== '######') && <i className="pi pi-exclamation-circle"
                                                     style={{...style.icon, color: 'orange'}}
                                                     onClick={this.activateVoucher.bind(this, row)}>
                    </i>
                )
              }} style={{width: '75px', textAlign: 'center'}}/>
              <Column body={(row) => {
                return ((row.enabled === '******') ?
                  <a href={`${WEBSHOP}/${this.props.webshop.slug}/#/${row.username}/${row.token}`} target="_blank"
                     rel="noreferrer">
                    <Button icon="pi pi-user"
                            tooltip={`Login als ${row.username}`}
                            className="p-button-success p-button-outlined" style={{
                      width: '1.75rem',
                      height: '1.75rem',
                      fontSize: '0.5rem', cursor: 'pointer'
                    }}/>
                  </a>
                  : null)
              }} style={{width: '50px', textAlign: 'center'}}/>


            </DataTable>
          </div>
        </div>
      </div>
    );
  }

  state = {
    rows: [],
    dialogAddVisible: false,
    voucher_amount: 0
  }

  constructor(props) {
    super(props)
    this.AddVouchersDialog = React.createRef()
    this.DialogAreYouSure = React.createRef()
    this.UploadVouchersDialog = React.createRef()
    this.DialogVoucherOrders = React.createRef()
    this.EditVoucherDialog = React.createRef()

    this.getVouchers = this.getVouchers.bind(this)
    this.onDialogAddedVouchers = this.onDialogAddedVouchers.bind(this)
    this.onDialogUploadedVouchers = this.onDialogUploadedVouchers.bind(this)
    this.state.voucher_amount = props.webshop?.voucher_amount
  }

  componentDidMount() {
    this.getVouchers()
  }

  showOrders(voucher) {
    this.DialogVoucherOrders.current.open(voucher)
  }

  getVouchers() {
    axios.get(BACKEND + '/get-vouchers', {params: {webshop_id: this.props.match.params.webshop_id}}).then((response) => {
      if (response.data.msg === 'ok') {
        this.setState({'rows': response.data.rows})
      } else {
        alert(response.data)
      }

    }).catch((error) => {
    });

  }

  textEditor(props) {
    let {field, rowData, value, rowIndex} = props;
    return <InputText
      value={rowData[field]}
      style={{width: '4rem', textAlign: 'right'}}
      onChange={(e) => {
        value[rowIndex][field] = e.target.value;
        this.setState({rows: value});
      }}/>
  }

  saveColumn(props) {
    let {field, rowData} = props.columnProps
    let data = {
      id: rowData.id,
      key: field,
      value: rowData[field]
    }

    axios.post(BACKEND + '/set-voucher', data).then(response => {
      // all good baby
    }).catch(error => {
      alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
    });
    return true;
  }

  newPassword(row, e) {

    this.DialogAreYouSure.current.open(
      "Je staat op het punt een nieuw wachtwoord\n" +
      "aan te maken voor deze voucher. Het oude\n" +
      "wachtwoord is dan niet meer bruikbaar.\n" +
      "Vergeet niet de voucher te activeren!\n\n" +
      "Als je zeker bent klik dan op OK?\n\n", () => {

        let {rows} = this.state;
        axios.post(BACKEND + '/new-password-for-voucher', {id: row.id}).then(res => {
          if (res.data.msg === 'ok') {
            let newRow = rows.filter((i) => i.id === row.id)[0];
            newRow.enabled = res.data.pass;
            this.setState({rows: rows})
          }
        }).catch(error => {
          alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
        });

      }, row.username)

  }

  activateAllVouchers() {
    this.DialogAreYouSure.current.open(
      "Je staat op het punt alle vouchers activeren.\n" +
      "Het wachtwoord is na deze actie niet meer te\n" +
      "achterhalen. Download eerst de nog niet actieve\n" +
      "vouchers.\n\n" +
      "Als je zeker bent klik dan op OK?\n\n", () => {

        this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});

        axios.post(BACKEND + '/activate-all-vouchers', {webshop_id: this.props.match.params.webshop_id}).then(response => {
          this.messages.clear();
          if (response.data.msg === 'ok') {
            let detail = "" + response.data.details.total + " vouchers zijn succesvol bijgewerkt."
            this.messages.show({severity: 'success', summary: 'Verwerkt!', detail: detail, sticky: true});
            this.getVouchers()
          } else {
            let detail = "" + response.data.details.ok + " van de " + response.data.details.total + " vouchers zijn succesvol bijgewerkt."
            this.messages.show({severity: 'warn', summary: 'Verwerkt met fouten...', detail: detail, sticky: true});
            this.getVouchers()
          }

        }).catch(error => {
          this.messages.clear();
          this.messages.show({
            severity: 'error',
            summary: 'Fouten',
            detail: "Er is iets fout gegaan!\n" + error + "\nHerlaadt de pagina en probeer nogmaals.",
            sticky: true
          });
        });
      }, "Mass activate vouchers")

  }

  deleteVouchers() {
    this.DialogAreYouSure.current.open(
      "Je staat op het punt alle vouchers (zonder orders) van deze webshop (ID: "+this.props.match.params.webshop_id+") te verwijderen.\n" +
      "Als je zeker bent klik dan op OK?\n\n", () => {

        this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});

        axios.post(BACKEND + '/delete-vouchers', {webshop_id: this.props.match.params.webshop_id}).then(response => {
          this.messages.clear();
          if (response.data.msg === 'ok') {
            this.messages.show({severity: 'success', summary: 'Verwerkt!', sticky: true});
            this.getVouchers()
          } else {
            this.messages.show({severity: 'warn', summary: 'Verwerkt met fouten...', sticky: true});
            this.getVouchers()
          }

        }).catch(error => {
          this.messages.clear();
          this.messages.show({
            severity: 'error',
            summary: 'Fouten',
            detail: " Er is iets fout gegaan!\n" + error + "\nHerlaadt de pagina en probeer nogmaals.",
            sticky: true
          });
        });
      }, "Mass activate vouchers")

  }

  sendLoginLink() {
    this.DialogAreYouSure.current.open(
      "Je staat op het punt alle vouchers (met een '%' bij infix) een inlog link te sturen.\n\n" +
      "Als je zeker bent klik dan op OK?\n\n", () => {

        this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});

        axios.post(BACKEND + '/send-login-link', {webshop_id: this.props.match.params.webshop_id}).then(response => {
          this.messages.clear();
          if (response.data.msg === 'ok') {
            let detail = " " + response.data.details.total + " mails verzonden."
            this.messages.show({severity: 'success', summary: 'Verwerkt!', detail: detail, sticky: true});
            this.getVouchers()
          } else {
            let detail = "" + response.data.details.ok + " van de " + response.data.details.total + " mails verzonden."
            this.messages.show({severity: 'warn', summary: 'Verwerkt met fouten...', detail: detail, sticky: true});
            this.getVouchers()
          }

        }).catch(error => {
          this.messages.clear();
          this.messages.show({
            severity: 'error',
            summary: 'Fouten',
            detail: "Er is iets fout gegaan!\n" + error + "\nHerlaadt de pagina en probeer nogmaals.",
            sticky: true
          });
        });
      }, "Inlog link versturen")

  }

  disableOrEnableVoucher(row, e) {

    if (row.enabled === '######') {
      // We're disabled so enable
      this.DialogAreYouSure.current.open(
        "Je staat op het punt een onbruikbaar gemaakte voucher weer bruikbaar te maken.\n\n" +
        "Vergeet niet eerst het nieuwe wachtwoord op te slaan en dan de voucher te activeren.\n\n"
        , () => {

          let {rows} = this.state;

          axios.post(BACKEND + '/enable-voucher', {id: row.id}).then(response => {
            if (response.data.msg === 'ok') {
              let newRow = rows.filter((i) => i.id === row.id)[0]
              newRow.enabled = response.data.password
              this.setState({rows: rows})
            }
          })
        }, row.username)

    }

    if (row.enabled !== '######') {
      this.DialogAreYouSure.current.open(
        "Je staat op het punt deze voucher onbruikbaar te maken.\n\n" +
        "Weet je dit zeker?\n\n", () => {

          let {rows} = this.state;

          axios.post(BACKEND + '/disable-voucher', {id: row.id}).then(response => {
            if (response.data.msg === 'ok') {
              let newRow = rows.filter((i) => i.id === row.id)[0]
              newRow.enabled = '######'
              newRow.token = null
              this.setState({rows: rows})
            }
          })
        }, row.username)
    }

  }

  activateVoucher(row, e) {
    this.DialogAreYouSure.current.open(
      "Je staat op het punt deze voucher te activeren.\n" +
      "Het wachtwoord is na deze actie niet meer te\n" +
      "achterhalen. Download eerst de nog niet actieve\n" +
      "vouchers.\n\n" +
      "Als je zeker bent klik dan op OK?\n\n", () => {


        let {rows} = this.state;

        axios.post(BACKEND + '/activate-voucher', {id: row.id}).then(response => {
          if (response.data.msg === 'ok') {
            let newRow = rows.filter((i) => i.id === row.id)[0]
            newRow.enabled = '******'
            newRow.token = response.data.token
            this.setState({rows: rows})
          }

        }).catch(error => {
          console.log(error);
          alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
        });
      }, row.username)

  }

  onDialogUploadedVouchers() {
    this.getVouchers()
  }

  onDialogAddedVouchers() {
    this.getVouchers()
  }


}


export default Vouchers
