import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import axios from "axios";
import DialogProductProperties from "../dialogs/DialogProductProperties";
import {Button} from "primereact/button";
import {priceFilter} from '../../helpers/helpers'
import {Messages} from "primereact/messages";
import DialogAreYouSure from "../dialogs/DialogAreYouSure";
import * as JaNee from '../../enum/ja_nee';
import { Dropdown } from 'primereact/dropdown';


const BACKEND = process.env.REACT_APP_MTG_BACK

let style = {
  sync: {
    backgroundColor: 'white',
    float: 'right',
    marginTop: '-3.5rem',
    padding: '0 0.8rem',
    color: '#c8c8c8'
  },
  col: {
    height: '2.5em', padding: '0.3em'
  },
  butt: {
    fontSize: '0.75rem',
    width: '2rem'
  }
}

export class Products extends Component {

  constructor(props) {
    super(props)

    this.state = {
      popupEditProductVisible: true,
      rows: [],
      sidebarVisible: false,
      waitingForSync: false
    }

    this.DialogProductProperties = React.createRef()
    this.DialogAreYouSure = React.createRef()

  }

  render() {
    return (
      <div>
        <Messages style={{width: '80%'}} ref={(el) => this.messages = el}></Messages>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
        <DialogProductProperties ref={this.DialogProductProperties}/>

        {/*=======================================================*/}
        <span style={style.sync}>
            <Button title="Leveranciers Sync"
                        className={"p-mr-2 " + (this.state.waitingForSync ? 'p-button-primary' : 'p-button-info')}
                        icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'}
                        style={style.butt}
                        onClick={() => this.syncProductSellersWithOdoo()}/>
            <Button title="Product Sync"
                    className={"p-mr-2 " + (this.state.waitingForSync ? 'p-button-primary' : 'p-button-success')}
                    icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'}
                    style={style.butt}
                    onClick={() => this.syncSimpleProductsWithOdoo()}/>


            {/*<Button title="Voorraad"*/}
            {/*        className={"p-mr-2 " + (this.state.waitingForSync ? 'p-button-secondary' : 'p-button-secondary')}*/}
            {/*        icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-list'}*/}
            {/*        style={style.butt}*/}
            {/*        onClick={() => this.syncProductsStock()}/>*/}


            {/*<Button title="Volledige producten sync (langzaam)"*/}
            {/*        className={(this.state.waitingForSync ? 'p-button-secondary' : 'p-button-secondary')}*/}
            {/*        icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'}*/}
            {/*        style={style.butt}*/}
            {/*        onClick={() => this.syncProductsWithOdoo()}/>*/}
        </span>
        {/*=======================================================*/}

        {/* DATA */}
        <DataTable paginator={true}
                   rows={15}
                   value={this.state.rows}
                   editable={true}
                   style={{marginTop: '5px'}}>
          {/*=======================================================*/}
          <Column style={{width: '3rem'}}
                  body={(row) => {
                    return (
                      <Button
                        icon="pi pi-pencil"
                        tooltip="Edit product..."
                        style={style.butt}
                        onClick={() => {
                          this.DialogProductProperties.current.open(row.id, row.display_name)
                        }}/>)
                  }}/>
          {/*=======================================================*/}
          <Column
            field="id"
            header={"Odoo ID"}
            sortable={true}
            filter={true}
            filterPlaceholder={'...'}
            style={{width: '6rem', textAlign: 'center'}}/>
          {/*=======================================================*/}
          <Column
            field="display_name"
            header={"Naam"}
            sortable={true}
            filter={true}
            filterMatchMode={'contains'}
            filterPlaceholder={'Bevat...'}
            style={{...style.col}}/>
          {/*=======================================================*/}
          <Column
            field="standard_price"
            header={"Kostprijs"}
            sortable={true}
            filter
            filterMatchMode="custom"
            filterPlaceholder={'.. tot ..'}
            filterFunction={priceFilter}
          />
          {/*=======================================================*/}
          <Column
            field="list_price"
            header={"Verkoopprijs"}
            sortable={true}
            filter
            filterMatchMode="custom"
            filterPlaceholder={'.. tot ..'}
            filterFunction={priceFilter}
          />
          {/*=======================================================*/}
          <Column
            field="mtg_seller_name"
            header={"Leverancier"}
            sortable={true}
            filter={true}
            filterMatchMode={'contains'}
            filterPlaceholder={'Bevat...'}
            style={{...style.col}}/>
          {/*=======================================================*/}
          <Column
            field="category_name"
            header={"Productcategory"}
            sortable={true}
            filter={true}
            filterMatchMode={'contains'}
            filterPlaceholder={'Bevat...'}
            style={{...style.col}}/>
          {/*=======================================================*/}
          <Column
            field="virtual_available"
            header={"Virtuele voorraad"}
            sortable={true}
            filter={true}
            filterMatchMode="custom"
            filterPlaceholder={'.. tot ..'}
            filterFunction={priceFilter}/>
          {/*=======================================================*/}
          <Column field="checked" header="Checked"
            editorValidator={this.saveColumn}
            sortable={true}
            style={style.center}
            body={(rowData) => this.renderCheckedDropdown(rowData)}
          />
          {/*=======================================================*/}
          <Column style={{width: '3rem'}}
                  body={(row) => {
                    return (

                      <Button icon="pi pi-times"
                              tooltip="Delete product..."
                              className="p-button-danger"
                              onClick={() => {
                                this.deleteProduct(row)
                              }}
                              style={style.butt}/>

                    )
                  }}/>

        </DataTable>
      </div>
    );
  }

  componentDidMount() {
    this.getRows()
  }

  deleteProduct(row) {
    this.DialogAreYouSure.current.open(`\nProduct '${row.display_name}' verwijderen?\n\n\n`, () => {
      axios.post(BACKEND + '/product-delete', {id: row.id}).then(res => {
        let copyRows = [...this.state.rows]
        copyRows.splice(copyRows.findIndex(e=>e.id === row.id),1)
        this.setState({rows:copyRows})
        const msg = <div style={{paddingLeft: '2rem'}}>
          <h3 style={{marginTop: '0'}}>{row.display_name}</h3>
          <div>Met succes verwijderd!</div>
        </div>

        this.messages.show({severity: 'success', summary: msg, sticky: false});

      }).catch(error => {

        const msg = <div style={{paddingLeft: '2rem'}}>
          <h3 style={{marginTop: '0'}}>{row.display_name}</h3>
          <div>{error.response.data.errors.map(e => <div>{e}</div>)}</div>
        </div>

        this.messages.show({severity: 'error', summary: msg, sticky: false});
      });

    })


  }

  syncProductsWithOdoo() {
    this.setState({waitingForSync: true})
    this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-products-with-odoo').then(res => {
      this.getRows()
      this.setState({waitingForSync: false})

      this.messages.clear();
      if (res.data === true) {
        this.messages.show({
          severity: 'success',
          summary: 'De producten zijn succesvol gesynchroniseerd.',
          sticky: true
        });
      } else {
        this.messages.show({
          severity: 'error',
          summary: 'Fouten',
          detail: (
            <React.Fragment>Er is iets fout gegaan!<br/>{res.data}<br/>Herlaadt de pagina en probeer
              nogmaals</React.Fragment>),
          sticky: true
        });
      }
    });
  }

  syncProductsStock() {
    this.setState({waitingForSync: true})
    this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-stock').then((res) => {
      this.getRows()
      this.setState({waitingForSync: false})

      this.messages.clear();
      if (res.data === 1) {
        this.messages.show({
          severity: 'success',
          summary: 'De producten zijn succesvol gesynchroniseerd.',
          sticky: true
        });
      } else {
        this.messages.show({
          severity: 'error',
          summary: 'Fouten',
          detail: (
            <React.Fragment>Er is iets fout gegaan!<br/>{res.data}<br/>Herlaadt de pagina en probeer
              nogmaals</React.Fragment>),
          sticky: true
        });
      }
    });

  }

  syncProductSellersWithOdoo() {
    this.setState({waitingForSync: true})
    this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-all-product-sellers').then((res) => {
      this.getRows()
      this.setState({waitingForSync: false})

      this.messages.clear();

      if (res.data.success) {
        this.messages.show({
          severity: 'success',
          summary: res.data.count+' leveranciers zijn gesynct, er zijn nog '+res.data.no_sellers+ ' producten zonder leveranciers',
          sticky: true
        });
      } else {
        this.messages.show({
          severity: 'error',
          summary: 'Fouten',
          detail: (
            <React.Fragment>Er is iets fout gegaan!<br/>{res.data}<br/>Herlaadt de pagina en probeer
              nogmaals</React.Fragment>),
          sticky: true
        });
      }
    });
  }

  syncSimpleProductsWithOdoo() {
    this.setState({waitingForSync: true})
    this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-all-products').then((res) => {
      this.getRows()
      this.setState({waitingForSync: false})

      this.messages.clear();
      if (res.data === 1) {
        this.messages.show({
          severity: 'success',
          summary: 'De producten zijn succesvol gesynchroniseerd.',
          sticky: true
        });
      } else {
        this.messages.show({
          severity: 'error',
          summary: 'Fouten',
          detail: (
            <React.Fragment>Er is iets fout gegaan!<br/>{res.data}<br/>Herlaadt de pagina en probeer
              nogmaals</React.Fragment>),
          sticky: true
        });
      }
    });
  }

  getRows() {
    axios.get(BACKEND + '/get-products').then(response => {
      this.setState({rows: response.data})
    });
  }

  textEditor(props) {
    let {field, rowData, value, rowIndex} = props;
    return <InputText value={rowData[field]} onChange={(e) => {
      value[rowIndex][field] = e.target.value;
      this.setState({rows: value});
    }}/>
  }

  saveColumn(props) {
    const { field, rowData } = props;

    if (!rowData || !rowData.id) {
      console.error('Invalid rowData or missing id:', rowData);
      alert('Invalid product data. Please refresh and try again.');
      return;
    }

    const data = {
      id: rowData.id,
      key: field,
      value: rowData[field],
    };

    axios.post(BACKEND + '/set-productfield', data)
      .then(() => {
        console.log(`Successfully saved column field: ${field}`);
      })
      .catch((error) => {
        console.error('Error saving column field:', error);
        alert('\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.');
      });
  }

  renderCheckedDropdown(rowData) {
    return (
      <Dropdown
        value={rowData.checked}
        options={JaNee.options}
        onChange={(e) => this.handleDropdownChange(e, rowData, 'checked')}
        style={{ width: '100%' }}
      />
    );
  }

  handleDropdownChange(e, rowData, field) {
    const updatedValue = e.value;
    const updatedRows = this.state.rows.map((row) =>
      row.id === rowData.id ? { ...row, [field]: updatedValue } : row
    );
    this.setState({ rows: updatedRows });
    this.saveColumn({ field, rowData: { ...rowData, [field]: updatedValue } });
  }

}

export default Products
