import React, {Component} from 'react'
import {Menubar} from 'primereact/menubar'
import {Button} from 'primereact/button'
import TrafficLight from '../widgets/TrafficLight'
import axios from 'axios'
import {UserContext} from "../contexts/UserContext"

class Menu extends Component {
  static contextType = UserContext

  role = (name) => {return this.context.hasRole(name)}


  getItems() {
    // Always Home (/)
    let allItems = [{
      icon: 'pi pi-fw pi-home',
      command: () => this.props.history.push('/')
    }]

    if (this.role('user') || this.role('admin')) {
      allItems.push(...this.userItems)
    }

    if(this.role('admin')){
      allItems.push(...[
        {
          label: 'Settings',
          icon: 'pi pi-cog',
          command: () => this.props.history.push('/settings'),
        },
        {
        label: 'App Users',
        icon: 'pi pi-fw pi-user',
        command: () => this.props.history.push('/users')
        },
        {
          label: 'Client Users',
          icon: 'pi pi-fw pi-users',
          command: () => this.props.history.push('/client-users')
        }])
    }
    return allItems
  }

  render() {

    return <Menubar
      model={this.getItems()}
      end={
        <Button label={this.context.userName}
                icon="pi pi-power-off"
                onClick={() => this.logout()}
                style={{marginRight: '0.5em', float: "right"}}/>}
    />

  }



  logout() {
    axios.post(process.env.REACT_APP_MTG_AUTH + '/logout').then(
      (res) => {
        if (res.data.msg === 'ok') {
          // userId and api_token
          sessionStorage.clear()
          this.context.setUserName('')
          this.context.setUserRoles([])
          axios.defaults.headers.common['Authorization'] = null
          // Jump
          this.props.history.push('/');
        }
      })

  }
  userItems = [
    {
      label: 'Webshops',
      icon: 'pi pi-fw pi-shopping-cart',
      command: () => this.props.history.push('/webshops'),
    },

    {
      label: 'Producten',
      icon: 'pi pi-fw pi-th-large',
      command: () => this.props.history.push('/products'),
    },
    {
      label: 'Partners',
      icon: 'pi pi-fw pi-users',
      command: () => this.props.history.push('/partners'),
    },
    {
      label:
        <React.Fragment>
          <TrafficLight/>
          <span style={{marginLeft: '1.6rem'}}>Reports</span>
        </React.Fragment>,
      command: () => this.props.history.push('/reports/prognose'),
    },
    {
      label: 'Helpdesk',
      icon: 'pi pi-thumbs-up',
      command: () => this.props.history.push('/helpdesk'),
    }
  ]
}

export default Menu;





