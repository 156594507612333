import React, {Component} from 'react'
import axios from 'axios'
import {InputText} from 'primereact/inputtext'
import money from '../../services/money'
import {lookup as warehouseLookup} from '../../enum/warehouses'
import * as Btw from '../../enum/btw'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import toNlDate from '../../services/toNlDate'

const inpStyleNumber = {width: '100%', textAlign: 'right', backgroundColor: 'gainsboro'}
const inpStyleText = {width: '100%', textAlign: 'left', backgroundColor: 'gainsboro'}

class ProductProperties extends Component {

  render() {
    if (!this.state.product) return null;
    let {product} = this.state;
    return (
      <div style={{fontSize: '0.9rem', lineHeight: '2rem'}}>
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col"/>
        </div>
        <div className="p-grid">
          <div className="p-col-4">ID</div>
          <div className="p-col-4">
            <InputText style={inpStyleNumber} readOnly={true} value={this.state.product.id}/>
          </div>
          <div className="p-col-4" style={{textAlign: 'right'}}>
            <Button className={this.state.waitingForSync ? 'p-button-secondary' : 'p-button-success'}
                    icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'}
                    style={{fontSize: '0.75rem'}}
                    onClick={() => this.syncProductWithOdoo()}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">Titel</div>
          <div className="p-col-8">
            <InputText style={inpStyleText} readOnly={true} value={this.state.product.display_name}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">Titel (engels)</div>
          <div className="p-col-8">
            <InputText style={inpStyleText}
                       readOnly={true}
                       value={product.display_name_en_US ? product.display_name_en_US : ''}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">Prijs</div>
          <div className="p-col-2">Standard</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
                       value={money.toEuro(this.state.product.standard_price, true)}/>
          </div>
          <div className="p-col-2">List</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
                       value={money.toEuro(this.state.product.list_price, true)}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-2">Internet</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
                       value={money.toEuro(this.state.product.internet_price, true)}/>
          </div>
          <div className="p-col-2">BTW</div>
          <div className="p-col-2">
            <Dropdown style={inpStyleNumber} value={this.state.product.taxes_id} options={Btw.products}
                      disabled={true}/>
          </div>
        </div>

        {/* NEW FIELDS: SCRAPER PRICE AND SCRAPER URL */}
        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-2">Scraper Price</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
              value={
                (this.state.product.scraper_price && this.state.product.scraper_price !== 'Price not available')
                  ? `€ ${this.state.product.scraper_price}`
                  : 'Price not available'
              } />
          </div>
          <div className="p-col-2">Scraper URL</div>
          <div className="p-col-2">
            {this.state.product.scraper_url && this.state.product.scraper_url !== '#' ? (
              <a href={this.state.product.scraper_url} target="_blank" rel="noopener noreferrer">
                <Button label="View" icon="pi pi-external-link" className="p-button-link"/>
              </a>
            ) : (
              <Button label="View" icon="pi pi-external-link" className="p-button-link" disabled />
            )}
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-8" style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
            <Button
              label="Get Scraper Info"
              icon="pi pi-info-circle"
              className={
                !this.state.product.barcode || this.state.product.barcode.length !== 13
                  ? 'p-button-secondary'
                  : this.state.buttonClicked
                    ? 'p-button-success p-disabled'
                    : 'p-button-success'
              }
              disabled={!this.state.product.barcode || this.state.product.barcode.length !== 13 || this.state.buttonClicked}
              onClick={() => this.getScraperInfo()}
            />
            {this.state.isLoading && (
              <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem', marginLeft: '10px' }}></i>
            )}
          </div>
        </div>

        <hr/>

        {/* Voorraad */}
        <div className="p-grid">
          <div className="p-col-4">Voorraad</div>
          <div className="p-col-2">Fysiek</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.mtg_qty_available === null ? '' : this.state.product.mtg_qty_available}/>
          </div>
          <div className="p-col-2">Virtueel</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.mtg_virtual_available === null ? '' : this.state.product.mtg_virtual_available}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-2">Min. Hoeveelheid</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.mtg_product_min_qty === null ? '' : this.state.product.mtg_product_min_qty}/>
          </div>
          <div className="p-col-2">
            Show
          </div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.show_on_webshop}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">
            Warehouse
          </div>
          <div className="p-col-2">
            <InputText style={inpStyleText}
                       readOnly={true}
                       value={warehouseLookup(this.state.product.mtg_leveren_van_magazijn_id)}/>

          </div>

          <div className="p-col-2">Limited Edition</div>
          <div className="p-col-2" style={{textAlign: 'right'}}>
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.limited_edition ? 'Yes' : 'No'}/>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">
            Odoo Leverdatum
          </div>
          <div className="p-col-2">
            <InputText style={{...inpStyleText, textAlign:'center'}}
                       readOnly={true}
                       value={toNlDate(this.state.product.odoo_date_planned)}/>

          </div>
        </div>

        <hr/>

        {/*Types etc*/}
        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">
            Product Type
          </div>
          <div className="p-col-2">
            <InputText style={inpStyleText}
                       readOnly={true}
                       value={product.type}/>

          </div>

          <div className="p-col-2">MTG Product Type</div>
          <div className="p-col-2" style={{textAlign: 'right'}}>
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={product.mtg_product_type ? product.mtg_product_type : ''}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">Leveranciers ID</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={product.mtg_seller_id}/>
          </div>

          <div className="p-col-2">Leveranciers Naam</div>
          <div className="p-col-2" style={{textAlign: 'right'}}>
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={product.mtg_seller_name}/>
          </div>
        </div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  state = {
    webshoporder_id: null,
    product: null,
    waitingForSync: false,
    isLoading: false
  }

  constructor(props) {
    super(props);
    this.state.webshoporder_id = props.webshoporder_id;
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-product-detail', {
      params: { product_id: this.props.product_id }
    })
    .then(response => {
      const productData = response.data;
      this.setState({
        product: {
          ...productData,
          scraper_price: 'Price not available',
          scraper_url: '#'
        }
      });
    })
      .catch(() => {
        this.setState({
          product: {
            scraper_price: 'Price not available',
            scraper_url: '#'
          }
        });
      });
  }

  getScraperInfo() {
    if (this.state.product.barcode && this.state.product.barcode.length === 13) {
      this.setState({ buttonClicked: true, isLoading: true }); 

      axios.get('https://cors-anywhere.herokuapp.com/https://api.shoppingscraper.com/offers/', {
        params: {
          site: 'bol.com',
          ean: this.state.product.barcode,
          api_key: '7f81a6e7-def2-4d8d-a518-06562902ea47'
        },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        validateStatus: () => true
      })
        .then(scraperResponse => {
          const results = scraperResponse.data.results || [];
          const firstResult = results[0];
          if (firstResult && firstResult.offers?.length) {
            const nieuwOffers = firstResult.offers.filter(offer => offer.condition === "Nieuw");

            if (nieuwOffers.length) {
              const cheapest = nieuwOffers.reduce((min, offer) => {
                const price = parseFloat(offer?.price) || 0;
                return price < (min?.price || Infinity) ? offer : min;
              }, { price: Infinity });

              this.setState(prevState => ({
                product: {
                  ...prevState.product,
                  scraper_price: cheapest?.price || 'Price not available',
                  scraper_url: firstResult?.url || '#'
                },
                isLoading: false
              }));
            }
          } else {
            this.setState(prevState => ({
              product: {
                ...prevState.product,
                scraper_price: 'Price not available',
                scraper_url: '#'
              },
              isLoading: false 
            }));
          }
        })
        .catch(() => {
          this.setState(prevState => ({
            product: {
              ...prevState.product,
              scraper_price: 'Price not available',
              scraper_url: '#'
            },
            isLoading: false
          }));
        });
    } else {
      console.error("Barcode is invalid or missing.");
    }
  }

  syncProductWithOdoo() {
    this.setState({waitingForSync: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-one-product?id=' + this.state.product.id).then((res) => {
      this.props.onsync();
      this.setState({waitingForSync: false});
    })
  }

}

export default ProductProperties
