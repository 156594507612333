const toNlDate = (dateStr) => {
  if (!dateStr) {
    return '';
  }
  const date = new Date(dateStr + ' UTC');

  return date.toLocaleString('nl-NL', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });


}
export default toNlDate
