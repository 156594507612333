import React, {Component} from 'react'
import moment from 'moment'
import axios from 'axios'
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {InputMask} from 'primereact/inputmask';
import {MultiSelect} from 'primereact/multiselect';

import DialogAlert from '../dialogs/DialogAlert'
import DialogAreYouSure from '../dialogs/DialogAreYouSure'
import * as Deliverymethod from "../../enum/deliverymethod";
import * as Btw from "../../enum/btw";
import {Tooltip} from "primereact/core";


const inpStyle = {width: '100%'}
const BACKEND = process.env.REACT_APP_MTG_BACK

class WebshopProperties extends Component {

  render() {

    if (!this.state.webshop) return null;
    return (
      <div style={{fontSize: '1rem'}}>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
        <DialogAlert ref={this.DialogAlert}/>
        {/* ====================== Line 1 ========================== */}
        <div className="p-grid">
          <div className="p-col-3">Relatienaam</div>
          <div className="p-col-5">
            <InputText ref={this.DisplayName || ''}
                       style={inpStyle}
                       readOnly={true}
                       value={this.state.webshop.partner_display_name}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right', marginTop: '0.4rem'}}>
            Relatie Id
          </div>
          <div className="p-col-1">
            <InputText ref={this.DisplayName || ''}
                       style={{...inpStyle, textAlign: 'right'}}
                       readOnly={true}
                       value={this.state.webshop.partner_id}/>
          </div>
        </div>
        {/* ====================== Line 2 ========================== */}
        <div className="p-grid">
          <div className="p-col-3">Webshop Naam</div>
          <div className="p-col-5">
            <InputText ref={this.DisplayName || ''}
                       style={inpStyle}
                       value={this.state.webshop.display_name}
                       onChange={(e) => {
                         this.setState({webshop: {...this.state.webshop, display_name: e.target.value}})
                       }}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right', marginTop: '0.4rem'}}>
            Webshop Id
          </div>
          <div className="p-col-1">
            <InputText ref={this.DisplayName || ''}
                       style={{...inpStyle, textAlign: 'right'}}
                       readOnly={true}
                       value={this.state.webshop.id}/>
          </div>
        </div>
        {/* ====================== Line 3 ========================== */}
        <div className="p-grid">
          {/* === Date === */}
          <div className="p-col-3">Openingsdatum</div>
          <div className="p-col-1">
            <Calendar inputStyle={{textAlign: 'center', width: '7rem'}}
                      dateFormat="yy-mm-dd" value={moment(this.state.webshop.start_date).toDate()}
                      onChange={(e) => {
                        this.setState({webshop: {...this.state.webshop, start_date: e.value}})
                      }}/>
          </div>
          <div className="p-col-3">
            <InputMask mask="99:99"
                       style={{textAlign: 'center', width: '4rem'}}
                       value={this.state.webshop.start_time}
                       onChange={(e) => this.setState({webshop: {...this.state.webshop, start_time: e.value}})
                       }/>
          </div>
          <div className="p-col-1"/>
          {/* === Right part === */}
          <div className="p-col-2" style={{textAlign: 'right', marginTop: '0.4rem'}}>
            Eco's opmaken
          </div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState(
                    {
                      webshop: {
                        ...this.state.webshop,
                        round_order: this.state.webshop.round_order ? 0 : 1
                      }
                    }
                  )
                }}
              checked={!!this.state.webshop.round_order}/>
          </div>
        </div>
        {/*Line 4*/}
        <div className="p-grid">
          <div className="p-col-3">Sluitingsdatum</div>
          <div className="p-col-1">
            <Calendar inputStyle={{textAlign: 'center', width: '7rem'}}
                      dateFormat="yy-mm-dd" value={moment(this.state.webshop.end_date).toDate()}
                      onChange={(e) => {
                        this.setState({webshop: {...this.state.webshop, end_date: e.value}})
                      }}/>
          </div>
          <div className="p-col-3">
            <InputMask mask="99:99"
                       style={{textAlign: 'center', width: '4rem'}}
                       value={this.state.webshop.end_time}
                       onChange={(e) => this.setState({webshop: {...this.state.webshop, end_time: e.value}})
                       }/>
          </div>
          <div className="p-col-1"/>
          <div className="p-col-2" style={{textAlign: 'right', marginTop: '0.4rem'}}>
            Archiveer
          </div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.DialogAreYouSure.current.open("Je staat op het punt een webshop te \n(de) archiveren.\n\nWeet je dit zeker?\n\n", () => {
                    this.setState({webshop: {...this.state.webshop, active: this.state.webshop.active ? 0 : 1}})
                  })
                }
              }
              checked={this.state.webshop.active === 0}/>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Webshop url</div>
          <div className="p-col-5">
            <InputText style={inpStyle}
                       value={this.state.webshop.slug || ''}
                       onChange={(e) => {
                         this.setState({
                           webshop: {
                             ...this.state.webshop,
                             slug: e.target.value
                           }
                         })
                       }}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Afkorting</div>
          <div className="p-col-2">
            <InputText style={inpStyle}
                       value={this.state.webshop.local_abbr || ''}
                       onChange={(e) => {
                         this.setState({
                           webshop: {
                             ...this.state.webshop,
                             local_abbr: e.target.value
                           }
                         })
                       }}/>

          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Webshop / Kerstmarkt</div>
          <div className="p-col-5">
            <Dropdown value={this.state.webshop.type}
                      options={[
                        {label: 'webshop', value: 'webshop'},
                        {label: 'kerstmarkt', value: 'kerstmarkt'},
                      ]}
                      style={{width: '100%'}}
                      onChange={(e) => {

                        this.setState({webshop: {...this.state.webshop, type: e.value}})
                      }}/>

          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Demo mode</div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {
                      ...this.state.webshop,
                      local_stockcontrol_off: this.state.webshop.local_stockcontrol_off ? 0 : 1
                    }
                  })
                }
              }
              checked={this.state.webshop.local_stockcontrol_off !== 0}/>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Aflevermethodes</div>
          <div className="p-col-5">
            <Dropdown value={this.state.webshop.deliverymethods} options={Deliverymethod.options}
                      style={{width: '100%'}}
                      onChange={(e) => {
                        this.setState({webshop: {...this.state.webshop, deliverymethods: e.value}})
                      }}/>

          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Eco's bijkopen</div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {
                      ...this.state.webshop,
                      local_buy_credits: this.state.webshop.local_buy_credits ? 0 : 1
                    }
                  })
                }
              }
              checked={this.state.webshop.local_buy_credits !== 0}/>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Landen</div>
          <div className="p-col-5">
            <MultiSelect style={{width: '100%'}}
                         optionLabel="name"
                         optionValue="id"
                         value={this.state.webshop.selected_countries}
                         options={this.state.webshop.available_countries}
                         display="chip"
                         onChange={(e) => {
                           this.setState({webshop: {...this.state.webshop, selected_countries: e.value}})
                         }}
            />
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Welkom scherm</div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState(
                    {
                      webshop: {
                        ...this.state.webshop,
                        welcome_screen: this.state.webshop.welcome_screen ? 0 : 1
                      }
                    }
                  )
                }}
              checked={!!this.state.webshop.welcome_screen}/>
          </div>
        </div>

        {/* RIBBONS */}
        <div className="p-grid">
          <div className="p-col-3">Getoonde ribbons</div>
          <div className="p-col-5">
            <MultiSelect style={{width: '100%'}}
                         optionLabel="name"
                         optionValue="id"
                         value={this.state.webshop.selected_ribbons}
                         options={this.state.webshop.ribbons}
                         display="chip"
                         onChange={(e) => {
                           this.setState({webshop: {...this.state.webshop, selected_ribbons: e.value}})
                         }}
            />

          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Welkom buttons uitzetten?</div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState(
                    {
                      webshop: {
                        ...this.state.webshop,
                        buttons_off: this.state.webshop.buttons_off ? 0 : 1
                      }
                    }
                  )
                }}
              checked={!!this.state.webshop.buttons_off}/>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Budget incl / excl BTW</div>
          <div className="p-col-5">
            <Dropdown value={this.state.webshop.fiscal_position_id} options={Btw.options}
                      style={{width: '100%'}}
                      onChange={(e) => {
                        this.setState({webshop: {...this.state.webshop, fiscal_position_id: e.value}})
                      }}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Uitsluiten van bulk wijzigingen?</div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState(
                    {
                      webshop: {
                        ...this.state.webshop,
                        exclude_bulk: this.state.webshop.exclude_bulk ? 0 : 1
                      }
                    }
                  )
                }}
              checked={!!this.state.webshop.exclude_bulk}/>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Budget in euro’s</div>
          <div className="p-col-5">
            <InputText style={{...inpStyle, textAlign: 'right'}}
                       value={this.state.webshop.euro_amount || ''}
                       onChange={(e) => {
                         this.setState({webshop: {...this.state.webshop, euro_amount: e.target.value}})
                       }}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}><Tooltip target=".local_exclude_prognose"/> <span
            className="local_exclude_prognose"
            data-pr-tooltip="Wanneer de webshoporder niet meer meegeteld moet worden in de prognose kan hier het vinkje gezet worden.">Uitsluiten van prognose?</span>
          </div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {
                      ...this.state.webshop,
                      local_exclude_prognose: this.state.webshop.local_exclude_prognose ? 0 : 1
                    }
                  })
                }
              }
              checked={!!this.state.webshop.local_exclude_prognose}/>
          </div>
        </div>
        <div className="p-grid" style={{ marginBottom: '5px' }}>
          <div className="p-col-3">Budget in Eco's</div>
          <div className="p-col-5">
            <InputText style={{...inpStyle, textAlign: 'right'}}
                       value={this.state.webshop.voucher_amount || ''}
                       onChange={(e) => {
                         this.setState({webshop: {...this.state.webshop, voucher_amount: e.target.value}})
                       }}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Welkom pagina intro video (iframe)</div>
          <div className="p-col-2">
            <InputText style={inpStyle}
                       value={this.state.webshop.welcome_screen_intro || ''}
                       onChange={(e) => {
                         this.setState({
                           webshop: {
                             ...this.state.webshop,
                             welcome_screen_intro: e.target.value
                           }
                         })
                       }}/>

          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-3">Eco afronding</div>
          <div className="p-col-5">
            <InputText style={{...inpStyle, textAlign: 'right'}}
                       value={this.state.webshop.eco_threshold || ''}
                       onChange={(e) => {
                         this.setState({webshop: {...this.state.webshop, eco_threshold: e.target.value}})
                       }}/>
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Plein (iframe)</div>
          <div className="p-col-2">
            <InputText style={inpStyle}
                       value={this.state.webshop.plein_iframe || ''}
                       onChange={(e) => {
                         this.setState({
                           webshop: {
                             ...this.state.webshop,
                             plein_iframe: e.target.value
                           }
                         })
                       }}/>

          </div>
        </div>

        {/*LATER ONTVANGEN & CADEAU GEVEN*/}
        <div className="p-grid" style={{marginTop: '0.3em'}}>
          <div className="p-col-3">Winkelwagen</div>
          <div className="p-col-2">
            Later Ontvangen
          </div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState(
                    {
                      webshop: {
                        ...this.state.webshop,
                        local_cart_delivery_time: this.state.webshop.local_cart_delivery_time ? 0 : 1
                      }
                    }
                  )
                }}
              checked={!!this.state.webshop.local_cart_delivery_time}/>
          </div>
          <div className="p-col-3" style={{textAlign: 'right'}}>Custom header</div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {...this.state.webshop, custom_header: this.state.webshop.custom_header ? 0 : 1}
                  })
                }
              }
              checked={!!this.state.webshop.custom_header}/>
          </div>

          <div className="p-col-3"/>
          <div className="p-col-2">
            Cadeau Geven
          </div>
          <div className="p-col-2">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState(
                    {
                      webshop: {
                        ...this.state.webshop,
                        local_cart_gif_away: this.state.webshop.local_cart_gif_away ? 0 : 1
                      }
                    }
                  )
                }}
              checked={!!this.state.webshop.local_cart_gif_away}/>
          </div>
          <div className="p-col-3" style={{textAlign: 'right'}}>Zelf aantal gift points bepalen?</div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {
                      ...this.state.webshop,
                      allow_custom_gift_points: this.state.webshop.allow_custom_gift_points ? 0 : 1
                    }
                  })
                }
              }
              checked={!!this.state.webshop.allow_custom_gift_points}/>
          </div>
        </div>
        {/*TOEVOEGEN*/}
        <div className="p-grid" style={{marginTop: '0.3em'}}>
          <div className="p-col-3">Gratis Toevoegen</div>
          <div className="p-col-5">
            <MultiSelect style={{width: '100%'}}
                         optionLabel="name"
                         optionValue="id"
                         value={this.state.addons}
                         options={this.state.free_products}
                         display="chip"
                         onChange={(e) => {
                           this.setState({addons: e.value})
                         }}
            />
          </div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Cycloon vervoerder</div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {...this.state.webshop, cycloon: this.state.webshop.cycloon ? 0 : 1}
                  })
                }
              }
              checked={!!this.state.webshop.cycloon}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-3"></div>
          <div className="p-col-5"></div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Homepage</div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {...this.state.webshop, homepage: this.state.webshop.homepage ? 0 : 1}
                  })
                }
              }
              checked={!!this.state.webshop.homepage}/>
          </div>
        </div>

        <div className="p-grid" style={{marginTop: '0.3em'}}>
          <div className="p-col-3"></div>
          <div className="p-col-5"></div>
          <div className="p-col-2" style={{textAlign: 'right'}}>Login met e-mail</div>
          <div className="p-col-1">
            <Checkbox
              onMouseDown={
                () => {
                  this.setState({
                    webshop: {...this.state.webshop, email_login: this.state.webshop.email_login ? 0 : 1}
                  })
                }
              }
              checked={!!this.state.webshop.email_login}/>
          </div>
        </div>

        {/*SAVE*/}
        <div className="p-grid" style={{marginTop: '0.3em'}}>
          <div className="p-col-3"></div>
          <div className="p-col-5">
            <Button label="Save"
                    onClick={() => this.save()}
                    style={{width: '100%'}}/>
          </div>
        </div>


      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  state = {
    visible: false,
    webshop: null,
    webshops: null,
    ribbonInput: '',
    free_products: [],
    addons: [],
    searchSuggestions: [],
    searchTextAutocomplete: ''
  }

  constructor(props) {
    super(props)

    this.DisplayName = React.createRef()
    this.DialogAlert = React.createRef()
    this.DialogAreYouSure = React.createRef()

  }

  componentDidMount() {


    // Get all free products
    axios.get(BACKEND + '/get-webshoplines?working=0&webshop_id=' + this.props.match.params.webshop_id).then(res => {
      let fp = []
      res.data.rows.forEach((item) => {
        if (item.price_in_eco === 0) {
          const el = {id: item.product_id, name: item.display_name}
          fp.push(el)
        }
      })
      this.setState({free_products: fp}, () => {
        // Then get the wso props
        axios.get(BACKEND + '/get-webshop',
          {
            params: {webshop_id: this.props.match.params.webshop_id}
          }).then((res) => {
          res.data.start_time = res.data.start_date.split(' ')[1].substr(0, 5)
          res.data.end_time = res.data.end_date.split(' ')[1].substr(0, 5)

          this.setState({
            webshop: res.data,
            addons: JSON.parse(res.data.local_free_products || '[]'),
            originalBudget: res.data.voucher_amount
          })
        });

      })

    });

  }

  shorten(text) {
    if (!text)
      text = 'Edit'
    return text.replace(/(<([^>]+)>)/ig, " ").substring(0, 30) + '...';
  }


  save() {
    const webshop_id = this.props.match.params.webshop_id;
    let webshop = this.state.webshop;
    const originalBudget = this.state.originalBudget; 
    const currentBudget = webshop.voucher_amount;

    if (originalBudget !== currentBudget) {
        axios.get(`${BACKEND}/get-webshoplines`, { params: { working: 0, webshop_id: webshop_id } })
        .then((res) => {
            const products = res.data.rows || [];  
            if (products.length > 0) {
                const mostExpensiveProduct = products.reduce((prev, current) => current.price_in_eco > prev.price_in_eco ? current : prev);
                this.DialogAreYouSure.current.open(
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px', fontWeight: 'bold', color: '#d9534f', textAlign: 'center', marginBottom: '15px' }}>
                            <span style={{ fontSize: '24px', color: '#f0ad4e', marginRight: '10px' }}>⚠️</span>
                            <span>Deze webshop bevat producten !</span>
                            <span style={{ fontSize: '24px', color: '#f0ad4e', marginLeft: '10px' }}>⚠️</span>
                        </div>
                        <div style={{ fontSize: '16px', color: '#333', marginTop: '10px', textAlign: 'center', fontWeight: 'bold' }}>
                           Weet u zeker dat u het veld 'Budget in Eco's' wilt bijwerken?
                        </div>
                        <div style={{ fontSize: '14px', color: '#555', marginTop: '10px', textAlign: 'center', marginBottom: '15px' }}>
                          Het duurste product is {mostExpensiveProduct.price_in_eco} Eco's.
                        </div>
                    </>, () => { this.saveWebshop(webshop); });
          } else {
            this.saveWebshop(webshop);
          }
        })
        .catch((err) => console.error("Error while checking webshop products:", err));
    } else {
      this.saveWebshop(webshop);
    }
  }

  saveWebshop(webshop) {
    webshop.start_date = moment(webshop.start_date).format('YYYY-MM-DD')
    webshop.end_date = moment(webshop.end_date).format('YYYY-MM-DD')
    webshop.local_free_products = JSON.stringify(this.state.addons)
    axios.post(BACKEND + '/set-webshop', webshop).then((res) => {

      switch (res.data.msg) {
        case 'ok':
          this.DialogAlert.current.open('', null, 'Saved successfully!', 'center')
          break;
        case 'error':
          let erros = res.data.data.map((rst, i) => <div key={i}>{rst}</div>)
          this.DialogAlert.current.open(erros,
            null,
            <span style={{color: 'red'}}>One or more error(s) occured!</span>,
            'center')
          break;
        default:
          console.log(res.data);
      }


    }).catch((err) => {
      console.log(err)
    })
  }


  change(val, field) {

    let webshop = {
      ...this.state.webshop
    }

    webshop[field] = val
    this.setState({webshop})
  }


}

export default WebshopProperties
