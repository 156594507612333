import React, {Component} from 'react'
import {Card} from 'primereact/card'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {Password} from 'primereact/password'
import {Messages} from 'primereact/messages'
import {Fieldset} from 'primereact/fieldset'
import Menu from '../components/Menu'
import axios from 'axios'
import {UserContext} from "../contexts/UserContext";

class PageLogin extends Component {
  static contextType = UserContext
  state = {
    email: '',
    password: '',
    svg: '',
    secret: '',
    step: '',
    two_fa: ''
  }

  render() {

    return (
      <div>
        <Menu {...this.props}/>
        <Fieldset legend="Login" style={{fontSize: '1.3rem'}}>

          <div className="Login p-grid">
            <div className="p-col-12 p-md-6 p-lg-4 p-xl-4"/>

            <div className="p-col-12 p-md-6 p-lg-4 p-xl-4">

              {this.state.step === '' && this.loginHtml()}
              {this.state.step === 'create_two_fa_secret' && this.qrCodeHtml()}
              {this.state.step === 'check_two_fa' && this.get2faHtml()}
              <Messages ref={(el) => this.messages = el}></Messages>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4 p-xl-4"/>
          </div>
        </Fieldset>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.qrcoderef = React.createRef()
    this.twofaref = React.createRef()
  }

  get2faHtml() {
    return <Card>
      <div style={{textAlign: 'center'}}>
        <h3>Vul hier de Google Authenticator response in</h3>

        <div style={{paddingTop: '1rem'}}>
          <InputText value={this.state.two_fa}
                     ref={this.twofaref}
                     onChange={(e) => {
                       this.setState({two_fa: e.target.value}, ()=>{
                         if(this.state.two_fa.length === 6){
                          this.submit()
                         }
                       })
                     }}
                     style={{width: '100'}}/>
        </div>
        {/*<div style={{paddingTop: '1rem'}}>*/}
        {/*  <Button label="Login" onClick={() => this.submit()} style={{marginRight: '.25em'}}/>*/}
        {/*</div>*/}
      </div>
    </Card>
  }

  qrCodeHtml() {
    return <Card>
      <div style={{textAlign: 'center'}}>
        <h3>Scan deze QR code met Google 2Fa app</h3>
        <div ref={this.qrcoderef}/>
        <div>
          <div>Vul de response in en klik op OK</div>
        </div>
        <div style={{paddingTop: '1rem'}}>
          <InputText value={this.state.two_fa}
                     onChange={(e) => {
                       this.setState({two_fa: e.target.value})
                     }}
                     style={{width: '100px'}}/>
        </div>
        <div style={{paddingTop: '1rem'}}>
          <Button label="OK" onClick={() => this.submit2fa()} style={{marginRight: '.25em'}}/>
        </div>
      </div>
    </Card>
  }

  loginHtml() {
    return <Card footer={<Button label="Login" onClick={() => this.submit()} style={{marginRight: '.25em'}}/>}>

      <div className="p-grid">
        <div className="p-col-12">
          <div className="p-inputgroup">
            <div className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </div>
            <InputText value={this.state.email}
                       placeholder="Username"
                       onChange={(e) => {
                         this.setState({email: e.target.value})
                       }}
                       style={{width: '100%'}}
                       onKeyDownCapture={(e) => {
                         if (e.key === "Enter") {
                           this.submit();
                         }
                       }}/>
          </div>
        </div>

        <div className="p-col-12">
          <div className="p-inputgroup">
            <div className="p-inputgroup-addon">
              <i className="pi pi-lock"></i>
            </div>
            <Password value={this.state.password}
                      placeholder="Password"
                      feedback={false}
                      onChange={(e) => {
                        this.setState({password: e.target.value})
                      }}
                      style={{width: '100%'}}
                      onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                          this.submit();
                        }
                      }}/>
          </div>

        </div>
      </div>
    </Card>
  }

  createTwoFaSecret(data) {
    this.setState({
      svg: data.svg,
      secret: data.secret,
      step: data.step
    }, () => {
      /**
       * Create the QR code for 2FA
       */
      let blob = new Blob([this.state.svg], {type: 'image/svg+xml'});
      let url = URL.createObjectURL(blob);
      let image = document.createElement('img');
      image.src = url;
      image.style.width = '250px'
      image.addEventListener('load', () => URL.revokeObjectURL(url), {once: true});
      this.qrcoderef.current.innerHTML = image.outerHTML
    })

  }

  success(data) {
    // userId and api_token
    let AUTH_TOKEN = data.access_token
    sessionStorage.setItem('api_token', AUTH_TOKEN);
    sessionStorage.setItem('hostname', data.hostname);
    sessionStorage.setItem('username', data.user_name);
    sessionStorage.setItem('userroles', JSON.stringify(data.user_roles));
    this.context.setUserName(data.user_name)
    this.context.setUserRoles(data.user_roles)
    axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    // Jump
    this.props.history.push('/webshops');

  }

  check2fa(data) {
    this.setState({step: data.step}, () => {
      this.twofaref.current.focus()
    })
  }

  submit2fa() {
    const {email, password, secret, two_fa} = this.state
    axios.post(process.env.REACT_APP_MTG_AUTH + '/set-2fa', {
      email,
      password,
      secret,
      two_fa
    }).then(res => {
      this.success(res.data)
    }).catch((e) => {
      this.messages.show({
        severity: 'error',
        detail: 'Validation failed',
        closable: false
      });
    })
  }

  submit() {

    axios.post(process.env.REACT_APP_MTG_AUTH + '/login', this.state).then(
      (res) => {
        switch (res.data.step) {
          case 'success':
            this.success(res.data)
            break;
          case 'create_two_fa_secret':
            this.createTwoFaSecret(res.data)
            break;
          case 'check_two_fa':
            this.check2fa(res.data)
            break;
          default:
        }
      }
    ).catch((e) => {
      this.messages.show({
        severity: 'error',
        detail: 'Validation failed',
        closable: false
      });
    })
  }
}

export default PageLogin
