import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import axios from "axios"
import moment from 'moment'
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"

export class Waiting extends Component {

  render() {
    const { showDeleteColumn } = this.state;

    return (
      <div>
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col"/>
        </div>

        <div className="p-grid">
          <div className="p-col-4">
            <strong>Last job Started:</strong> {this.state.job_started}
          </div>
          <div className="p-col-2">
            <strong>Job locked:</strong> {this.state.lock ? 'Yes' : 'No'}
          </div>

          <div className="p-col-3">
            {this.state.lock === 1 &&
            <div>
              <strong>Job running for:</strong> {this.state.runtime} <strong>seconds</strong>
            </div>
            }
          </div>

          <div className="p-col-12">
            {/*=======================================================*/}
            <DataTable paginator={true}
                       rows={15}
                       value={this.state.rows}
                       style={{marginTop: '5px'}}>

              <Column header="Webshop" field="display_name" style={{width: '40rem'}}/>
              <Column header="Voornaam" field="firstname"/>
              <Column header="Achternaam" field="lastname"/>
              <Column header="Besteldatum" field="write_date"/>
              {showDeleteColumn && (
                <Column header="Verwijderen" body={(rowData) => <button type="button" className="p-button-danger p-link" onClick={() => this.openDeleteDialog(rowData)}><span className="pi pi-trash" /></button>} headerStyle={{ width: '10rem' }} bodyStyle={{ textAlign: 'center' }} />
              )}
            </DataTable>
            <Dialog visible={this.state.deleteDialogVisible} header="Confirm Deletion" modal onHide={this.closeDeleteDialog} footer={<div><Button label="No" icon="pi pi-times" onClick={this.closeDeleteDialog} className="p-button-text" /><Button label="Yes" icon="pi pi-check" onClick={this.confirmDeleteOrder} className="p-button-danger" /></div>}><p>Are you sure you want to delete this order?</p></Dialog>
          </div>
        </div>
      </div>
    );
  }

  state = {
    rows: [],
    job_started: '',
    lock: '',
    deleteDialogVisible: false,
    orderToDelete: null,
    showDeleteColumn: false,
    runtime: 0
  }

  constructor(props) {
    super(props);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this); 
    this.confirmDeleteOrder = this.confirmDeleteOrder.bind(this);
  }

  componentDidMount() {
    this.checkDeletePermission();
    this.getRows(true)
    // Check every minute
    this.intervalEveryMinute = setInterval(() => {
      this.getRows(false)
    }, 60000)
  }

  checkDeletePermission() {
    const urlParams = new URLSearchParams(window.location.search);
    const deleteOrder = urlParams.has('deleteOrder');
    this.setState({ showDeleteColumn: deleteOrder });
  }

  componentWillUnmount() {
    clearInterval(this.intervalEveryMinute);
    clearInterval(this.intervalEverySecond);
  }

  openDeleteDialog(user) {
    this.setState({ deleteDialogVisible: true, orderToDelete: user });
  }

  closeDeleteDialog() {
    this.setState({ deleteDialogVisible: false, orderToDelete: null });
  }

  confirmDeleteOrder() {
    const { orderToDelete } = this.state;
    axios
      .post(process.env.REACT_APP_MTG_BACK + '/delete-order', { id: orderToDelete.local_id
      })
      .then(() => {
        this.closeDeleteDialog();
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
        this.closeDeleteDialog();
      });
  }

  calcRuntime(job_started) {
    return Math.round((new Date().valueOf() - moment(job_started).valueOf()) / 1000);
  }

  getRows(runOnce) {
    axios.get(process.env.REACT_APP_MTG_BACK + '/report-nonsent-orders').then(response => {

      this.setState({
        rows: response.data.orders,
        job_started: response.data.cronlock.job_started,
        lock: response.data.cronlock.lock,
        runtime: this.calcRuntime(response.data.cronlock.job_started)
      }, () => {
        if (runOnce) {
          this.intervalEverySecond = setInterval(() => {
            this.setState({runtime: this.calcRuntime(this.state.job_started)})
          }, 1000)
        }
      })
    });
  }

}

export default Waiting
