import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import moment from 'moment'
import {Column} from 'primereact/column'
import {ToggleButton} from 'primereact/togglebutton'
import DialogProductProperties from '../../dialogs/DialogProductProperties'
import money from '../../../services/money'
import DownloadProductSales from '../../download/DownloadProductSales'
import DownloadProductSalesAll from '../../download/DownloadProductSalesAll'
import axios from "axios";
import {Link} from "react-router-dom";
import { Calendar } from 'primereact/calendar'

let typeMap = {
  consu:'Giftcard',
  product:'Product',
  service:'Goed doel'
}

let legenda = {
  padding: '2px 4px',
  margin: '0px 8px',
  border: 'solid 1px #ccc',
  borderRadius: '0.3rem'
}

export class Products extends Component {

  render() {

    return (
      <div>

        <DialogProductProperties ref={this.DialogProductProperties}/>

        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col-12"/>
        </div>

        {!this.state.product_id && <div className="p-grid">
          <div className="p-col-12">
            <ToggleButton offLabel="Producten"
                          onLabel="Producten"
                          checked={this.state.legenda.product}
                          onChange={(e) => {
                            this.legendaClicked('product', e)
                          }}
                          style={{marginLeft: '8px'}}/>
            <ToggleButton offLabel="Giftcards"
                          onLabel="Giftcards"
                          checked={this.state.legenda['consu']}
                          onChange={(e) => {
                            this.legendaClicked('consu', e)
                          }}
                          style={{marginLeft: '8px'}}/>
            <ToggleButton offLabel="Goede doelen"
                          onLabel="Goede doelen"
                          checked={this.state.legenda['service']}
                          onChange={(e) => {
                            this.legendaClicked('service', e)
                          }}
                          style={{marginLeft: '8px'}}/>
            <label style={{ marginLeft: '8px', display:'inline-block'}}>Start: </label>
            <Calendar inputStyle={{textAlign: 'center', width: '7rem'}}
                      dateFormat="yy-mm-dd"
                      onChange={(e) => {
                        this.dateChanged('start', e)
                      }}/>
            <label style={{ marginLeft: '8px', display:'inline-block'}}>Eind: </label>
            <Calendar inputStyle={{textAlign: 'center', width: '7rem'}}
                      dateFormat="yy-mm-dd"
                      onChange={(e) => {
                        this.dateChanged('end', e)
                      }}/>
            <DownloadProductSalesAll start={this.state.date.start} end={this.state.date.end}/>
          </div>
        </div>}

        <div className="p-grid">
          {/* ================ */}
          {/* All the webshops */}
          {/* ================ */}
          {!this.state.product_id &&
          <div className="p-col-12">
            <DataTable paginator={true}
                       ref={this.datatable_webshops_ref}
                       rows={20}
                       removableSort={true}
                       value={this.state.products}
                       style={{marginTop: '5px'}}>
              <Column field="id"
                      filter={true}
                      filterMatchMode={'contains'}
                      header="ID" className="t-right t-w-8"/>
              <Column field="display_name"
                      sortable={true}
                      filter={true}
                      filterMatchMode={'contains'}
                      header="Product"
                      body={(row) => {
                        return <Link to={`/reports/products/${row.id}`}>
                          {row.display_name}
                        </Link>
                      }}/>
              <Column field="type" header="Type" className="t-right t-w-8"
                      body={(row) => {
                        return typeMap[row.type]
                      }}/>

              <Column field="count" className="t-right t-w-8" sortable={true} header="Aantal"/>
              <Column className="t-right t-w-8" sortable={true} header="Totaal"
                      body={(row) => {
                        return money.toEuro(row.total_price, true)
                      }}
              />
              
            </DataTable>
          </div>
          }

          {/* ================ */}
          {/* Te actual report */}
          {/* ================ */}
          {this.state.product_id &&
          <div className="p-col-12">
            
            <Link style={{fontWeight: 'bold'}} to="/reports/products">{'<<< ' + this.state.pro.display_name}</Link>
            <span className="t-strong" style={{marginLeft: '0.5rem'}}>Totaal:</span>
            <span style={legenda}>{money.toEuro(this.state.sum.verkoopprijs, true)}</span>
            <label style={{ marginLeft: '8px', display:'inline-block'}}>Start: </label>
            <Calendar inputStyle={{textAlign: 'center', width: '7rem'}}
                      dateFormat="yy-mm-dd"
                      onChange={(e) => {
                        this.dateChanged('start', e,true)
                      }}/>
            <label style={{ marginLeft: '8px', display:'inline-block'}}>Eind: </label>
            <Calendar inputStyle={{textAlign: 'center', width: '7rem'}}
                      dateFormat="yy-mm-dd"
                      onChange={(e) => {
                        this.dateChanged('end', e,true)
                      }}/>
            <DownloadProductSales product_id={this.state.pro.id} slug={this.state.pro.slug} start={this.state.date.start} end={this.state.date.end}/>
          </div>
          }
          {this.state.product_id &&
          <div className="p-col-12">
            <DataTable paginator={true}
                       rows={20}
                       value={this.state.product_detail}>
              <Column field="wso_display_name" header="Webshop naam"/>
              <Column field="klant" header="Klant" style={{width: '10rem'}}/>
              <Column field="aantal"
                      className="t-center"
                      header={<span title="Aantal">#</span>}
                      style={{width: '2rem'}}/>
              <Column field="categorie"
                      header={<span title="Categorie">Categorie</span>}
                      style={{width: '10rem'}}/>
              <Column field="btw"
                      body={(row) => row.btw + '%'}
                      className="t-right"
                      header={<span title="BTW">BTW</span>}
                      style={{width: '3rem'}}/>
              <Column field="waarde_1_eco_excl_btw"
                      body={(row) => money.toEuro(row.waarde_1_eco_excl_btw, true)}
                      className="t-right"
                      header={<span title="Prijs Eco">Prijs Eco</span>}
                      style={{width: '5rem'}}/>
              <Column field="price_in_eco"
                      className="t-right"
                      header={<span title="Prijs Eco">Eco's</span>}
                      style={{width: '3rem'}}/>
              <Column field="verkoopprijs"
                      body={(row) => money.toEuro(row.verkoopprijs, true)}
                      className="t-right"
                      header={<span title="Verkoopprijs">Prijs</span>}
                      style={{width: '5rem'}}/>
              <Column field="kostprijs"
                      body={(row) => money.toEuro(row.kostprijs, true)}
                      className="t-right"
                      header={<span title="Kostprijs">Kost</span>}
                      style={{width: '4rem'}}/>
              <Column field="marge"
                      body={(row) => money.toEuro(row.marge, true)}
                      className="t-right"
                      header="Marge"
                      style={{width: '4rem'}}/>
              <Column field="marge_perc"
                      body={(row) => {
                        return money.toEuro(row.marge_perc) + '%'
                      }}
                      className="t-right"
                      header="Marge%"
                      style={{width: '5rem'}}/>
              <Column field="marktconform"
                      body={(row) => {
                        return row.marktconform === '-' ? '-' : money.toEuro(row.marktconform, true)
                      }}
                      className="t-right"
                      header={<span title="Marktconform">Markt</span>}
                      style={{width: '4rem'}}/>
            </DataTable>
          </div>
          }
        </div>
      </div>
    );
  }

  state = {
    product_id: null,
    products: [],
    product_detail: [],
    pro: null,
    legenda:
      {
        product: false,
        consu: false,
        service: false,
        date_start: false,
        date_end: false,
      },
    date: {
      start:false,
      end:false,
    },
    filter: [],
    sum: {
      verkoopprijs: -1
    }
  }

  constructor(props) {
    super(props);
    this.DialogProductProperties = React.createRef()
    this.datatable_webshops_ref = React.createRef()

  }

  /**
   *
   * @param legenda
   * @param e
   *
   * Some shitty code
   */
  legendaClicked(key, e) {

    let legenda = this.state.legenda
    legenda[key] = e.value

    // make filter array
    let filter = [];
    for (const [key, value] of Object.entries(legenda)) {
      if(value){
        filter.push(key)
      }
    }

    this.datatable_webshops_ref.current.filter(filter, 'type', 'in')
    this.setState({legenda, filter})
  }

  dateChanged(key, e)
  {
    let date = this.state.date
    date[key] = moment(e.value).format("YYYY-MM-DD HH:mm:ss")
    this.setState({date});
    if (this.state.product_id) {
      this.getProductDetail(this.state.product_id, true);
    } else {
      this.getProducts(true);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Horrible hack
    if (this.datatable_webshops_ref.current && !this.datatable_webshops_ref.current.state.filters) {
      this.datatable_webshops_ref.current.filter(this.state.filter, 'type', 'in')
    }

    if (this.props.location !== prevProps.location) {

      this.props.match.params.id
        ? this.getProductDetail(this.props.match.params.id)
        : this.setState({product_id: null})
    }
  }

  componentDidMount() {
    this.getProducts()
  }

  getProducts(date = false) {

    let url = process.env.REACT_APP_MTG_BACK + '/report-products-all-products';

    if (date) {
      date = '?date_start='+this.state.date.start+'&date_end='+this.state.date.end;
      url = process.env.REACT_APP_MTG_BACK + '/report-products-all-products'+date
    }
    
    axios.get(url).then(response => {
      this.setState({
        product_id: null,
        products: response.data,
      }, () => {
        setTimeout(() => {
          let input = this.datatable_webshops_ref.current.container.getElementsByTagName('input')[0]
          input.focus()
        }, 0)
      })
    })
  }

  getProductDetail(product_id, date = false) {

    let url = process.env.REACT_APP_MTG_BACK + '/report-sales?id=' + product_id + '&type=products';

    if (date) {
      date = '&date_start='+this.state.date.start+'&date_end='+this.state.date.end;
      url += date;
    }

    axios.get(url).then(response => {

      let sum = {
        verkoopprijs:0
      }
      response.data.result.forEach((row)=>{
        sum.verkoopprijs += row.verkoopprijs
      })

      this.setState({
        product_id: product_id,
        product_detail: response.data.result,
        pro: response.data.pro,
        sum
      })
    })
  }

}

export default Products
