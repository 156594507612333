import React from 'react'
import Menu from '../components/Menu'
import {Fieldset} from 'primereact/fieldset'
import {TabView, TabPanel} from 'primereact/tabview';
import Waiting from '../components/data/reports/Waiting'
import Prognose from '../components/data/reports/Prognose'
import Sales from '../components/data/reports/Sales'
import Products from '../components/data/reports/Products'
import Stock from '../components/data/reports/Stock'
import ReserveList from '../components/data/reports/ReserveList'
import Auth from '../auth/Auth'
import {WaitingForDelivery} from "../components/data/reports/WaitingForDelivery";

const tabs = {waiting: 0, prognose: 1, sales: 2, products: 3, stock: 4, nostock: 5, waitingfordelivery: 6}

class PageReports extends Auth {

  state = {
    tabIndex: -1
  }

  render() {
    if (this.state.tabIndex === -1) return null;
    return (
      <div>
        <Menu {...this.props}/>
        <Fieldset legend="Reports" style={{fontSize: '1.3rem'}}>
          <TabView activeIndex={this.state.tabIndex} onTabChange={(e) => this.onTabChange(e)}>
            <TabPanel header="Wachten">
              <Waiting {...this.props}/>
            </TabPanel>
            <TabPanel header="Prognose">
              <Prognose {...this.props}/>
            </TabPanel>
            <TabPanel header="Orders">
              <Sales {...this.props}/>
            </TabPanel>
            <TabPanel header="Producten">
              <Products {...this.props}/>
            </TabPanel>
            <TabPanel header="Stock">
              <Stock {...this.props}/>
            </TabPanel>
            <TabPanel header="Reserve lijst">
              <ReserveList {...this.props}/>
            </TabPanel>
            <TabPanel header="Wachten op levering">
              <WaitingForDelivery {...this.props}/>
            </TabPanel>
          </TabView>
        </Fieldset>
      </div>
    )
  }

  onTabChange(e) {

    // Change the url
    for (const [key, val] of Object.entries(tabs)) {
      if (e.index === val) {
        this.props.history.push('/reports/' + key)
        this.setState({tabIndex:val})
        return;
      }
    }
  }

  componentDidMount() {
    // Match the URL
    let tabIndex = tabs[this.props.match.params.report_name] || 0;
    this.setState({tabIndex})
    super.componentDidMount()
  }
}

export default PageReports

