import React, {Component} from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';

import {Button} from 'primereact/button';
import axios from 'axios';

import money from '../../services/money'
import * as Featured from '../../enum/featured'
import * as JaNee from '../../enum/ja_nee'
import * as Sort from '../../enum/sort'
import * as Btw from '../../enum/btw'
import {priceFilter} from '../../helpers/helpers'
import {InputSwitch} from 'primereact/inputswitch';
import DialogProductProperties from '../dialogs/DialogProductProperties';
import DialogEditField from '../dialogs/DialogEditField'
import ImportProductsDialog from '../dialogs/ImportProductsDialog';
import DownloadWebshopProducts from '../download/DownloadWebshopProducts'
import DialogAreYouSure from '../dialogs/DialogAreYouSure'

const BACKEND = process.env.REACT_APP_MTG_BACK

let style = {
  right: {textAlign: 'right'},
  center: {textAlign: 'center'},
}
let colorMap = {
  d_green: '#99CC66',
  c_yellow: '#FFFF99',
  b_orange: '#ff9f54',
  a_red: '#CC6666',
}

class Webshop extends Component {

  render() {
    return (
      <div className="p-grid" onKeyPress={this.handleKeyPress}>
        <DialogProductProperties ref={this.DialogProductProperties}/>
        <DialogEditField ref={this.DialogEditField}/>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>

        <ImportProductsDialog
          ref={this.ImportProductsDialog}
          {...this.props}/>
        {/*==================================================*/}
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-3">
              <span onClick={(e) => this.setState({working: !this.state.working})}
                    style={{
                      cursor: "pointer",
                      marginTop: '0.25rem',
                      marginRight: '1rem',
                      display: 'block',
                      float: 'left',

                    }}>
                Producten toevoegen
              </span>
              <InputSwitch checked={this.state.working} onChange={(e) => this.setState({working: e.value})}/>
            </div>

            <div className="p-col-2">
              <Button label="Importeer"
                      icon="pi pi-cloud-upload"
                      className="p-button-warning"
                      style={{margin: '0 2px', width: '10rem'}}
                      onClick={() => this.ImportProductsDialog.current.open(this.onDialogImportedProducts)}/>
            </div>

            <div className="p-col-2">
              <DownloadWebshopProducts webshop={this.props.webshop}/>
            </div>

            <div className="p-col-2">
              <Button label={`Vertaal (${this.state.nontranslated})`}
                      icon={this.state.translating ? 'pi pi-spin pi-spinner' : 'pi pi-google'}
                      className="p-button-success"
                      style={{margin: '0 2px', width: '10rem'}}
                      onClick={() => this.translateAll()}/>

            </div>

          </div>
        </div>
        {/*==================================================*/}
        <div className="p-col-12">
          <DataTable ref={this.DataTable}
                     value={this.state.rows}
                     paginator={true}
                     rows={20}
                     removableSort={true}
                     editable={true}
                     scrollable={false}
                     selectionMode={'single'}
                     selection={this.state.selectedRow}
                     onSelectionChange={(e) => {
                       this.setState({selectedRow: e.value})
                     }}
                     scrollHeight="700px">
            {/*==================================================================================*/}
            <Column
              style={{width: '2.4rem'}}
              body={(row) => {
                return row.id ?
                  '' :
                  <Button onClick={() => this.addProductClicked(row)} className="p-button-success"
                          icon="pi pi-fw pi-plus-circle ws-add-product" style={{fontSize: '0.6rem'}}/>

              }}/>
            {/*==================================================================================*/}
            <Column
              field="product_id"
              header={"Odoo ID"}
              sortable={true}
              filter={true}
              filterPlaceholder={'...'}
              style={{width: '6rem', textAlign: 'center'}}/>
            {/*==================================================================================*/}
            <Column
              field="display_name"
              header="Product"
              sortable={true}
              filter={true}
              filterMatchMode={'contains'}
              filterPlaceholder={'Zoek op naam...'}
              style={{width: '500px', textAlign: 'left'}}
              body={(row) => {
                return <span onClick={() => {
                  this.DialogProductProperties.current.open(
                    row.product_id,
                    row.display_name,
                    parseInt(this.props.match.params.webshop_id)
                  )
                }}>{row.display_name}</span>
              }}
            />
            {/*==================================================================================*/}
            <Column
              header=""
              style={{width: '2.4rem', textAlign: 'center'}}
              body={(row) => !row.local_no_image && <i className="pi pi-image"></i>}/>
            {/*==================================================================================*/}
            <Column
              header=""
              style={{width: '2.1rem', textAlign: 'center', color: '#666666', fontSize: '0.8rem'}}
              body={(row) => row.translate_me && <i className="pi pi-google"></i>}/>

           
            {/*==================================================================================*/}
            <Column field="price"
                    header="Price"
                    sortable={true}
                    style={style.right}
                    body={(row) => {
                      return money.toEuro(row.price, true)
                    }}
                    filter
                    filterMatchMode="custom"
                    filterPlaceholder={'.. tot ..'}
                    filterFunction={priceFilter}/>
            {/*==================================================================================*/}
            <Column field="price_in_eco"
                    header="Eco's"
                    sortable={true}
                    style={style.right}
                    body={(row) =>
                      <div onClick={() => this.editEco(row)}
                           className={row.price_in_eco > this.props.webshop.voucher_amount ? 't-red-strong' : ''}
                      >{row.price_in_eco}</div>
                    }
                    filter
                    filterMatchMode="custom"
                    filterPlaceholder={'.. tot ..'}
                    filterFunction={priceFilter}/>
            {/*=============================== BTW =======================================*/}
            <Column field="taxes_id" header="Btw" headerStyle={style.center}
                    body={(props) => {
                      return Btw.lookupProduct(props.taxes_id)
                    }}
                    style={style.right}
            />
            {/*============================= qty_available =====================================*/}
            <Column field="local_calculated_stock"
                    header="Voorraad"
                    headerStyle={style.center}
                    sortable={true}
                    filter
                    filterMatchMode="custom"
                    filterPlaceholder={'.. tot ..'}
                    filterFunction={priceFilter}
                    body={(props) => {
                      return <div style={{
                        backgroundColor: colorMap[props.traffic_light],
                        width: '100%',
                        float: 'right'
                      }}>
                        {props.local_calculated_stock}
                      </div>
                    }}
                    style={{...style.right}}/>
            {/*==================================================================================*/}
            <Column field="featured" header="Uitgelicht"
                    editorValidator={this.saveColumn}
                    sortable={true}
                    style={style.center}
                    body={(rowData) => this.renderFeaturedDropdown(rowData)}
            />
            {/*==================================================================================*/}
            <Column field="checked" header="Checked"
                    editorValidator={this.saveProduct}
                    sortable={true}
                    style={style.center}
                    body={(rowData) => this.renderCheckedDropdown(rowData)}
            />
            {/*==================================================================================*/}
            <Column field="local_order" header="Sort"
                    editorValidator={this.saveColumn}
                    sortable={true}
                    style={style.center}
                    body={(rowData) => this.renderOrderDropdown(rowData)}
            />

            {/*==================================================================================*/}
            <Column
              style={{width: '2.4rem'}}
              header={<React.Fragment>
                <Button
                  className="p-button-danger"
                  onClick={() => {
                    this.emptyWebshop()
                  }} icon="pi pi-trash" style={{height: '1.7rem', width:'1.7rem', marginLeft: '-0.5rem'}}
                />

              </React.Fragment>}
              body={(row) => {
                return row.id &&
                  <Button
                    className="p-button-warning"
                    onClick={() => {
                      this.removeProductClicked(row)
                    }} icon="pi pi-fw pi-trash" style={{height: '1.7rem', width:'1.7rem', marginLeft: '-0.2rem'}}
                  />
              }}/>
          </DataTable>
        </div>
      </div>
    );
  };

  state = {
    rows: [],
    working: false,
    filters: {},
    selectedRow: false,
    nontranslated: 0,
    translating: false
  }

  constructor(props) {
    super(props);

    this.DataTable = React.createRef()
    this.DialogProductProperties = React.createRef()
    this.ImportProductsDialog = React.createRef()
    this.DialogEditField = React.createRef()
    this.DialogAreYouSure = React.createRef()
    this.keyPressListener = this.keyPressListener.bind(this)
    this.textEditor = this.textEditor.bind(this)
    this.onDialogImportedProducts = this.onDialogImportedProducts.bind(this)
  }

  componentDidMount() {

    this.getRows()
    document.addEventListener("keydown", this.keyPressListener, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPressListener, false);
  }

  /**
   * Handles the double enters and clear selected row by key
   */
  enterCounter = 0;

  keyPressListener(e) {
    switch (e.key) {
      case 'Enter':
        if (this.state.selectedRow && this.enterCounter === 1 && this.state.selectedRow.id === null) {
          this.addProductClicked(this.state.selectedRow)
        }
        this.enterCounter++;
        break;
      case 'Escape':
        this.setState({selectedRow: false})
        break;
      default:
        this.enterCounter = 0
    }
  }

  emptyWebshop() {
    this.DialogAreYouSure.current.open(`Je staat op het punt een volledige webshop leeg te maken!\n\nWeet je dit zeker?\n\n`,
      () => {
        axios.get(BACKEND + '/truncate-webshop?webshop_id=' + this.props.match.params.webshop_id
        ).then(() => {
          this.getRows()
        });
      })
  }

  translateAll() {
    this.setState({translating: true});
    axios.get(BACKEND + '/translate-webshop?webshop_id=' + this.props.match.params.webshop_id
    ).then((res) => {
      this.setState({translating: false});
      this.getRows();
    });

  }

  getRows() {
    axios.get(BACKEND + '/get-webshoplines',
      {
        params: {
          working: this.state.working ? 1 : 0,
          webshop_id: this.props.match.params.webshop_id,
        }
      }
    ).then((response) => {
      // Count the not translated
      let nontranslated = 0;
      response.data.rows.forEach(item => {
        nontranslated += (item.translate_me ? 1 : 0);
      })

      this.setState({
          rows: response.data.rows,
          nontranslated
        },
        () => {

          // DEBBY //
          // let row = this.state.rows.find(el => el.product_id === 73424)
          // this.DialogProductProperties.current.open(
          //   row.product_id,
          //   row.display_name,
          //   parseInt(this.props.match.params.webshop_id)
          // )
          // DEBBY //
        })
    });
  }

  setFocusToFilter() {
    let inp = this.DataTable.current.container.getElementsByClassName('p-column-filter')[0]
    inp.focus()
    inp.select()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // When the toggle butt changes
    if (prevState.working !== this.state.working) {
      // clear filters
      this.DataTable.current.setState({filters: null})
      // Set focus when working
      if (this.state.working)
        this.setFocusToFilter()
      // Get new rows
      this.getRows()
    }
  }

  textEditor(props) {
    let {field, rowData, value, rowIndex} = props;
    return <InputText value={rowData[field]} onChange={(e) => {
      value[rowIndex][field] = e.target.value;
      this.setState({rows: value});
    }}/>
  }

  editEco(row) {
    this.DialogEditField.current.open(row.price_in_eco, (new_price_in_eco) => {
      row.price_in_eco = new_price_in_eco
      let this_state_rows = this.state.rows
      this_state_rows[this.state.rows.map(row => row.id).indexOf(row.id)] = row;
      this.setState({rows: this_state_rows});

      this.saveColumn({field: 'price_in_eco', rowData: row});
      // Change row state
    }, "Pas aantal Eco's aan")

  }

  renderFeaturedDropdown(rowData) {
    return (
      <Dropdown
        value={rowData.featured}
        options={Featured.options}
        onChange={(e) => this.handleDropdownChange(e, rowData, 'featured')}
        style={{ width: '100%' }}
      />
    );
  }

  renderCheckedDropdown(rowData) {
    return (
      <Dropdown
        value={rowData.checked}
        options={JaNee.options}
        onChange={(e) => this.handleDropdownChange(e, rowData, 'checked')}
        style={{ width: '100%' }}
      />
    );
  }

  renderOrderDropdown(rowData) {
    return (
      <Dropdown
        value={rowData.local_order}
        options={Sort.options}
        onChange={(e) => this.handleDropdownChange(e, rowData, 'local_order')}
        style={{ width: '100%' }}
      />
    );
  }

  handleDropdownChange(e, rowData, field) {
    const updatedValue = e.value;
    const updatedRows = this.state.rows.map((row) =>
      row.id === rowData.id ? { ...row, [field]: updatedValue } : row
    );
    this.setState({ rows: updatedRows });
    if (field === 'checked') {
      this.saveProduct({ field, rowData: { ...rowData, [field]: updatedValue } });
    } else {
      this.saveColumn({ field, rowData: { ...rowData, [field]: updatedValue } });
    }
  }

  saveColumn(props) {
    if (props.columnProps) {
      // Some hacky stuff...
      props = props.columnProps
    }
    let { field, rowData } = props;
    let data = {
      id: rowData.id,
      key: field,
      value: rowData[field]
    }
    axios.post(BACKEND + '/set-webshopline', data).then(response => {
      // all good baby
    }).catch(error => {
      alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
    });
    return true;
  }

  saveProduct(props) {
    const { field, rowData } = props;

    if (!rowData || !rowData.product_id) {
      console.error('Invalid rowData or missing product_id:', rowData);
      alert('Invalid product data. Please refresh and try again.');
      return;
    }

    const data = {
      id: rowData.product_id,
      key: field,
      value: rowData[field],
    };

    axios.post(BACKEND + '/set-productfield', data)
      .then(() => {
        console.log(`Successfully saved product field: ${field}`);
      })
      .catch((error) => {
        console.error('Error saving product field:', error);
        alert('\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.');
      });
  }

  addProductClicked(oldRow) {
    axios.post(BACKEND + '/add-webshopline', {
      product_id: oldRow.product_id,
      webshoporder_id: parseInt(this.props.match.params.webshop_id),
    }).then((res) => {

      if (res.data.msg === 'ok') {
        let tempRows = this.state.rows;
        tempRows[tempRows.map(e => e.product_id).indexOf(oldRow.product_id)] = res.data.row;
        this.setState({rows: tempRows});
        this.setFocusToFilter()
      } else {
        alert(res.data.msg ? res.data.msg : res.data);
      }
    }).catch((error) => {
      alert(error)
    });
  }

  removeProductClicked(oldRow) {
    axios.get(BACKEND + '/remove-webshopline', {
      params: {
        id: oldRow.id,
        webshop_id: this.props.match.params.webshop_id,
      }
    }).then((res) => {
      if (res.data.msg === 'ok') {

        let tempRows = this.state.rows;
        let index = tempRows.map(e => e.product_id).indexOf(oldRow.product_id);
        if (!this.state.working) {
          // Not in adding mode... remove from data
          tempRows.splice(index, 1)
        } else {
          // We're in adding mode.. adjust the data
          tempRows[index] = res.data.row
        }
        this.setState({rows: tempRows})
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  onDialogImportedProducts() {
    this.getRows()
  }
}


export default Webshop

