import React from 'react'
import Menu from '../components/Menu'
import Auth from '../auth/Auth'
import {Fieldset} from "primereact/fieldset";
import {TabPanel, TabView} from "primereact/tabview";
import Ribbons from "../components/data/settings/Ribbons";
import Email from "../components/data/settings/Email";
import {Button} from 'primereact/button';
class PageSettings extends Auth {
    state = {
        tabIndex: 0
    }

    openWinkelModus = () => {
        const token = sessionStorage.getItem('api_token');
        window.open(
          `${process.env.REACT_APP_MTG_WEBSHOP}/mtg-winkel-modus/#/0/${token}`,
          '_blank'
        );
      };

    render() {
        return (
            <div>
                <Menu {...this.props}/>
                <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Global settings">
                    <TabView activeIndex={this.state.tabIndex} onTabChange={(e) => this.onTabChange(e)}>
                        <TabPanel header="Ribbons">
                            <Ribbons/>
                        </TabPanel>
                        <TabPanel header="Email">
                            <Email/>
                        </TabPanel>
                        {/* New Winkel Modus Tab */}
                        <TabPanel header="Winkel Modus">
                            <div style={{ marginTop: '1rem' }}>
                                <p>
                                    Click the button below to open <strong>Winkel Modus</strong> in a new tab.
                                </p>
                                <Button
                                    label="Open Winkel Modus"
                                    icon="pi pi-external-link"
                                    onClick={this.openWinkelModus}
                                    className="p-button-success"
                                />
                            </div>
                        </TabPanel>

                    </TabView>
                </Fieldset>
            </div>
        )
    }
    onTabChange(e) {
        this.setState({tabIndex:e.index})
    }


}

export default PageSettings
